import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div>
      <h1 className='text-center text-xl py-12'>
        The S2 Underground Project is a covert intelligence agency, run by volunteers,<br/>with the goal of combating propaganda efforts, tyranny, human trafficking, and terrorism around the world.<br/>
      <StaticImage
        src="../images/mc-S2UndergroundLogo2.png"
        width={96}
        height={96}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt="S2 Underground"
        className='rounded-full text-center mt-6'
      />
      </h1>
    </div>
    <div
      className={`
        grid
        grid-1
        md:grid-cols-3
        lg:grid-cols-4
        gap-4
      `}
    >
      <Links />
    </div>
    <div className='topo
      my-8
      p-8
      rounded
      border
      border-solid
      border-gray-100
      text-center
    '>
      <h2 className='uppercase'>Mission</h2>
      <p className='text-xl'>
        All that is needed for evil to triumph in this world is for good men to
        stand by and do nothing. In a world of overwhelming governmental
        overreach, corporate oppression, genuine extremism, manufactured
        extremeism, foreign adversaries, crumbling infrastructure, and medical
        tyranny, we at the S2 Underground remain dedicated to using our skills
        for the betterment of human kind. Though we may be a small organization,
        we are deeply honored to serve our fellow man thrive in the unfortunate
        course of human events that have recently come to pass. We have no
        political, economic, or social motives. We are just a small group of
        friends hoping to help as many people as we can: The parent who doesn't
        want to inject their child with experimental drugs just so that they can
        go to school. The man who got dischared from the military because he
        liked the wrong post on social media. The woman who is trying to
        understand what is going on in the world, but cannot find any reliable
        sources in a sea of disinformation. Though we may be a small, part-time
        outfit, we hope that our efforts will empower citizens to better
        themselves and help them thrive in the tough times in which we live.
      </p>
    </div>
  </Layout>
)

const links = [
  {
    icon: "play_circle",
    title: "Our Videos",
    description:
      "Click the link above for our complete library of videos and other files, all hosted on Odysee.com",
    href: "https://odysee.com/@S2Underground:7"
  },
  {
    icon: "router",
    title: "Communications Plan",
    description:
      "Click the link above for a downloadable PDF of our latest Comm Card!",
    href: "/mc-COMMCARDVERSION2.5-1.pdf"
  },
  {
    icon: "menu_book",
    title: "Reading List",
    description:
      "Looking for a good book? We have created a list of books we recommend, sorted by subject area and all hosted on Goodreads",
    href: "https://www.goodreads.com/user/show/133747963-s2-actual"
  },
  {
    icon: "cloud",
    title: "Field Data Book",
    description: "Coming Soon! 🤫",
    href: "https://www.goodreads.com/user/show/133747963-s2-actual"
  },
  {
    icon: "sell",
    title: "Patreon",
    description:
      "For those of you who would like to support us on Patreon, the link is above!",
    href: "https://www.patreon.com/user?u=30479515"
  },
  {
    icon: "shopping_basket",
    title: "Merch Store",
    description:
      "If you would like to buy some stuff with our logo on it, here is the link to our Teespring store!",
    href: "https://s2-underground-store.creator-spring.com/?"
  },
  {
    icon: "chat",
    title: "Telegram Channel",
    description:
      "To recieve one-way updates from us throughout the week, here is our Telegram Channel",
    href: "https://t.me/s2_underground_project"
  },
  {
    icon: "podcasts",
    title: "Podcast",
    description:
      "This is the link to our OG Podcast, straight from the site we host it on.",
    href: "https://www.buzzsprout.com/868255"
  }
]

const Links = () =>
  links.map((link, i) => (
    <a key={i} href={link.href} className={`
      shadow-md
      rounded
      p-4
      text-center
      hover:shadow-xl
      border
      border-solid
      border-gray-100
      block
    `}>
      <i className="material-icons p-4 m-0 text-gray-400">{link.icon}</i>
      <h2>{link.title}</h2>
      <p>{link.description}</p>
    </a>
  ))

export default IndexPage
